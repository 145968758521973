var Flickity = require('flickity');
import 'flickity/css/flickity.css';
import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";


require('flickity-imagesloaded');

export const slidehows = () => {

    const queryString = window.location.search;


    /* var content = coll.nextElementSibling;

     const htmlElement = document.documentElement;
     const styles = getComputedStyle(htmlElement);
     const vhValue = parseFloat(styles.getPropertyValue('--vh').trim()) * 100;
     const hhValue = parseFloat(styles.getPropertyValue('--hh').trim());
     const ehValue = parseFloat(coll.scrollHeight);
     var contentHeight = vhValue - hhValue - ehValue + "px";

     coll.classList.add("active");
     content.style.minHeight = contentHeight;
     content.style.maxHeight = null;


     const pageContainer = document.querySelector('.page-container .fp-overflow');
     gsap.registerPlugin(ScrollToPlugin);
     setTimeout(function (){
         gsap.to(pageContainer, {
             duration: 0.3,
             scrollTo: coll.parentElement,
             onComplete: function() {
                 alert('ok');
             }
         });
     }, 600);*/


    const slideshows = document.querySelectorAll(".image-item");
    const projectsLineItems = document.querySelectorAll('.project-item');

    projectsLineItems.forEach((elem) => {

        elem.addEventListener('mouseenter', function () {
            const elemUid = elem.dataset.uid;
            const slideshow = document.querySelector('.image-item[data-uid="' + elemUid + '"]');
            slideshows.forEach((elem) => {
                elem.classList.remove('selected');
            });
            slideshow.classList.add('selected');
        });
    });


    var coll = document.querySelectorAll(".collapsible");
    var flkty;
    let flktyInstances = [];

    coll.forEach((elem) => {

        const closeBtn = elem?.querySelector('.cross');

        elem.addEventListener("click", function (e) {

            var urlQuery = window.location.search;
            let url = window.location.href;
            let query = "?"+elem.parentElement.dataset.uid;

            console.log(urlQuery);

            if (url.indexOf('?') === -1) {
                // Si aucun paramètre n'existe, ajouter "?le-voyage-a-nantes"
                const newUrl = url + query;
                history.pushState(null, '', newUrl);
            } else if(urlQuery !== query) {
                const newUrl = window.location.origin + window.location.pathname + query;
                history.pushState(null, '', newUrl);
            }

            function destroyAllFlickity() {
                console.log('flckty destroyed');
                flktyInstances.forEach((flkty) => {
                    if (flkty) {
                        console.log(flkty);
                        flkty.destroy();
                    }
                });
                flktyInstances = [];  // Clear the array after destruction
            }

            const htmlElement = document.documentElement;
            const styles = getComputedStyle(htmlElement);
            const vhValue = parseFloat(styles.getPropertyValue('--vh').trim()) * 100;
            const hhValue = parseFloat(styles.getPropertyValue('--hh').trim());
            const ehValue = parseFloat(elem.scrollHeight);
            var contentHeight = vhValue - hhValue - ehValue + "px";
            var content = this.nextElementSibling;


            if (parseFloat(contentHeight) < content.scrollHeight) {
                contentHeight = content.scrollHeight + "px";
            }

            if (content.style.minHeight) {
                this.classList.remove("active");
                content.style.minHeight = null;
                content.style.maxHeight = null;
                destroyAllFlickity();
            } else {

                coll.forEach((elem) => {
                    var content = elem.nextElementSibling;
                    elem.classList.remove("active");
                    if (content.style.minHeight) {
                        content.style.minHeight = null;
                        content.style.maxHeight = null;
                        destroyAllFlickity();
                    }
                });
                this.classList.add("active");
                content.style.minHeight = contentHeight;
                content.style.maxHeight = null;

                const slideshowId = elem.parentElement.dataset.uid;
                const slideshows = document.querySelectorAll(".carousel[data-uid='" + slideshowId + "']");

                slideshows.forEach((slideshow) => {
                    flkty = new Flickity(slideshow, {
                        cellAlign: 'left',
                        contain: true,
                        lazyLoad: 2,
                        autoPlay: false,
                        fade: true,
                        wrapAround: true,
                        selectedAttraction: 1,
                        pauseAutoPlayOnHover: false,
                        friction: 1,
                        pageDots: false,
                        prevNextButtons: true,
                    });

                    flktyInstances.push(flkty);
                });

                if (!elem.classList.contains('active')) {
                    destroyAllFlickity();
                }

            }

            e.preventDefault();
            const pageContainer = document.querySelector('.page-container .fp-overflow');
            gsap.registerPlugin(ScrollToPlugin);
            setTimeout(function () {
                gsap.to(pageContainer, {
                    duration: 0.3,
                    scrollTo: elem.parentElement,
                    onComplete: function () {
                    }
                });
            }, 200);

        });
    });

    if (queryString) {
        const menuItems = document.querySelectorAll('nav li a');

        menuItems.forEach((elem) =>{
            elem.classList.remove('active');
        });

        const ActivePage = document.body.getAttribute('id').split('/');
        const ActivePageId = ActivePage.pop();

        const activeMenuItem = document.querySelector('nav li a[href="/filter/'+ActivePageId+'"]');
        activeMenuItem.classList.add('active');

        var projectId = queryString.replace('?', '');
        var projectItem = document.querySelector('.project-item[data-uid="' + projectId + '"]');

        var event = new Event('mouseenter'); // Create a 'mouseenter' event
        projectItem.dispatchEvent(event);

        var ti = projectItem.querySelector('.collapsible');
        ti.click();
    }
}