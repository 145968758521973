__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import fullpage from 'fullpage.js';
import 'fullpage.js/dist/fullpage.css';
import Swup from 'swup';
import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";


import {slidehows} from "./_modules/slideshow";
import './_modules/menu';


document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();

  slidehows();

  const swup = new Swup({
    containers: ["#swup"]
  });

  swup.hooks.on('content:replace', () => {
    var id = (swup.location.pathname !== "/") ? swup.location.pathname.replace('/', '') : 'home';
    document.body.setAttribute("id", id);
  }, { before: true });

  swup.hooks.on('page:view', () =>{
    const scrollContainer = document.querySelector('.page-container .fp-overflow');
    scrollContainer.scrollTop = 0;
    onLoading();
    loadPage();
    slidehows();
  });


  var fullPageInstance = new fullpage('#fullpage', {
    licenseKey: 'gplv3-license',
    scrollingSpeed: 400,
    scrollBar: false,
    beforeLeave: function(origin, destination, direction, trigger){
      if(destination.index == 0){
        return false;
      }
    },
    afterRender: function(){
      const queryString = window.location.search;
      if (queryString) {
        var projectId = queryString.replace('?', '');
        var projectItem = document.querySelector('.project-item[data-uid="' + projectId + '"]');
        const pageContainer = document.querySelector('.page-container .fp-overflow');
        gsap.registerPlugin(ScrollToPlugin);
        setTimeout(function () {
          gsap.to(pageContainer, {
            duration: 0.3,
            scrollTo: projectItem,
            onComplete: function () {
            }
          });
        }, 200);
      }
    }
  });


});